import React, { useEffect, useState } from 'react'
import styles from "./AddCowrie.module.scss"
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCowries } from '../../../redux/slice/cowrieSlice';
import { addDoc, collection, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/Config';
import { toast } from 'react-toastify';
import Loader from '../../loader/Loader';
import Card from '../../card/Card';




const initialState = {
    plan: "",
    fee: "",
    custom: "",
    picture: "",
    vid: "",
    credit: "",
  };
  
  const AddCowrie = () => {
      const {id} = useParams()

    const cowries = useSelector(selectCowries);
     console.log(cowries);
      const cowrieEdit = cowries?.find((item) => item.id === id || initialState);
      const [cowrie, setCowrie] = useState(() => detectForm(id, initialState, cowrieEdit));
  
      const [ setUploadProgress] = useState(0)
      const [isLoading, setIsLoading] = useState(false)
      const navigate = useNavigate()
  
      useEffect(() => {
        if (cowrieEdit) {
          setCowrie(cowrieEdit);
        }
      }, [cowrieEdit]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setCowrie({ ...cowrie, [name]: value })
  }

  
  const adCowrie = async (e) => {
    e.preventDefault()
    setIsLoading(true);
    try {
      await addDoc(collection(db,"cowries"), {
        ...cowrie,
        createdAt: Timestamp.now().toDate(),
       
      });
      setIsLoading(false);
      setUploadProgress(0);
      setCowrie(initialState);
      toast.success("Price uploaded successfully");
      navigate("/admin/all-cowries");
    } catch (error) {
       setIsLoading(false);       
       toast.error(error.message);
     }
  }
  const editCowrie = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      try {
        const cowrieRef = doc(db, "cowries", id);
        await updateDoc(cowrieRef, {...cowrie,
          // updatedAt: Timestamp.now().toDate(),
          updatedAt: Timestamp.now().toDate().toISOString(), // Convert to ISO
        });
        setIsLoading(false);
        toast.success("Price updated successfully");
        navigate("/admin/all-cowries");
      } catch (error) {
        setIsLoading(false);
        toast.error(error.message);
      }
    };
  
  function detectForm(id, f1, f2) {
      return id === "ADD" ? f1 : f2;
    }
  
    return (
      <>
      {isLoading && <Loader />}
        <section className='container1'>
          <div className={styles.price}>
          <h2 className="h1">{detectForm(id, "ADD New Price", 
              "Edit Price")}</h2>
          <Card cardClass={styles.card}>
          <form onSubmit={detectForm(id, adCowrie , editCowrie)}>
       
        <label>Plan</label>
          <input type='text'
          placeholder=' plan'
          required
          name='plan'
          value={cowrie?.plan}
          onChange={(e)=>handleInputChange(e)}
          />
          <input type='text'
          placeholder=' fee'
          required
          name='fee'
          value={cowrie?.fee}
          onChange={(e)=>handleInputChange(e)}
          />
          <input type='text'
          placeholder=' custom'
          required
          name='custom'
          value={cowrie?.custom}
          onChange={(e)=>handleInputChange(e)}
          />
            <input type='text'
          placeholder=' picture'
          required
          name='picture'
          value={cowrie?.picture}
          onChange={(e)=>handleInputChange(e)}
          />
            <input type='text'
          placeholder=' vid'
          required
          name='vid'
          value={cowrie?.vid}
          onChange={(e)=>handleInputChange(e)}
          />
           <input type='text'
          placeholder=' credit'
          required
          name='credit'
          value={cowrie?.credit}
          onChange={(e)=>handleInputChange(e)}
          />
        <button className='--btn --btn-primary'>
          {detectForm(id, "Save Price", "Edit Price")}
        </button>
          </form>
          
          </Card>
          </div>
          </section>
      </>
    )
  }

export default AddCowrie


