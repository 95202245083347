import React, { useEffect } from 'react'
import YoofList from './yoofList/YoofList'
import { useDispatch, useSelector } from 'react-redux'
import useFetchCollection from '../../customHooks/useFetchCollection'
import { selectYoofs, STORE_YOOFS } from '../../redux/slice/yoofSlice'

const Yoof = () => {
    const {data} = useFetchCollection("yoofs")
    
    const yoofs = useSelector(selectYoofs)
      
     
       const dispatch = useDispatch()
   
       useEffect(() => {
        dispatch(STORE_YOOFS({yoofs: data}))
      }, [data, dispatch])
      
    return (
      <div>
       <YoofList yoofs={yoofs}/>
      </div>
    )
  }

export default Yoof