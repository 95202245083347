import React, { useEffect } from 'react'
import AboutList from './aboutList/AboutList'
import useFetchCollection from '../../customHooks/useFetchCollection'
import { useDispatch, useSelector } from 'react-redux'
import { selectAbouts, STORE_ABOUTS } from '../../redux/slice/aboutSlice'


const About = () => {
  const {data} = useFetchCollection("abouts")
  

     const abouts = useSelector(selectAbouts)

     const dispatch = useDispatch()

     useEffect(() => {
       dispatch(STORE_ABOUTS({abouts: data}))
     }, [data, dispatch])
     
  return (
    <div>
     <AboutList abouts={abouts}/>
    </div>
  )
}

export default About