import React from 'react'
import GoldItem from '../goldItem/GoldItem'
import { useSelector } from 'react-redux'
import { selectGolds } from '../../../redux/slice/goldSlice'

const GoldList = () => {
 const golds = useSelector(selectGolds)
    
    
      return (
        <> 
          <div>
            {golds?.map((gold) => {
                return (
                   <div key={gold.id}>
                 <GoldItem {...gold} 
                 gold = {gold} />
                 </div> 
                )
            }
                
            )}
            </div>
        </>
      )
    }

export default GoldList