import { Route, Routes } from "react-router-dom"
import Navbar from "../../components/admin/navbar/Navbar"
import styles from "./Admin.module.scss"
import AddAbout from "../../components/admin/addAbout/AddAbout"
import ViewAbout from "../../components/admin/viewAbout/ViewAbout"
import AddProduct from "../../components/admin/addProduct.js/AddProduct"
import ViewProducts from "../../components/admin/viewProducts/ViewProducts"
import AddYoofPrice from "../../components/admin/addYoofPrice/AddYoofPrice"
import ViewYoofPrice from "../../components/admin/viewYoofPrice/ViewYoofPrice"
import AddCowrie from "../../components/admin/addCowrie/AddCowrie"
import ViewCowries from "../../components/admin/viewCowries/ViewCowries"
import AddGold from "../../components/admin/addGold/AddGold"
import ViewGolds from "../../components/admin/viewGold/ViewGolds"


const Admin = () => {
  return (
    <div className={styles.admin}>
      <div className={styles.navbar}>
      <Navbar />
      </div>
      <div className={styles.content}>
        <Routes>
        <Route path="all-abouts" element={<ViewAbout />} />
        <Route path="add-about/:id" element={<AddAbout />} />
        <Route path="all-products" element={<ViewProducts />} />
        <Route path="add-product/:id" element={<AddProduct />} />
        <Route path="all-cowries" element={<ViewCowries />} />
        <Route path="add-cowrie/:id" element={<AddCowrie />} />
        <Route path="all-golds" element={<ViewGolds />} />
        <Route path="add-gold/:id" element={<AddGold />} />
        <Route path="all-yoofs" element={<ViewYoofPrice />} />
        <Route path="add-yoof/:id" element={<AddYoofPrice />} />
        </Routes>
    </div>
    </div>
  )
}
export default Admin