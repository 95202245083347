import React from 'react'
import styles from "./CowrieItem.module.scss"
import { Link } from 'react-router-dom'

const CowrieItem = ({ plan, fee, custom, vid, picture, credit }) => {
    return (
      <>
          <div className={styles["price-box"]}>
           <div className={styles.box1}>
           <p>{plan}</p>
              <h4>{fee}</h4>
           </div>
           <div className={styles["price-list"]}>
            <ul>
              <li>{custom}</li>
              <li>{vid}</li>
              <li>{picture}</li>
              <li>{credit}</li>
            </ul>
            <Link to="/contact" className={styles.btn1}>Subscribe</Link>
           </div>
          </div>
      </>
    )
  }

export default CowrieItem