import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { selectGolds } from '../../../redux/slice/goldSlice';
import { addDoc, collection, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/Config';
import { toast } from 'react-toastify';
import Loader from '../../loader/Loader';
import styles from "./AddGold.module.scss"
import Card from '../../card/Card';



const initialState = {
    deed: "",
    pay: "",
    order: "",
    photo: "",
    vit: "",
    orbit: "",
  };
  
  const AddGold = () => {
      const {id} = useParams()

    const golds = useSelector(selectGolds);
     console.log(golds);
      const goldEdit = golds?.find((item) => item.id === id || initialState);
      const [gold, setGold] = useState(() => detectForm(id, initialState, goldEdit));
  
      const [setUploadProgress] = useState(0)
      const [isLoading, setIsLoading] = useState(false)
      const navigate = useNavigate()
  
      useEffect(() => {
        if (goldEdit) {
          setGold(goldEdit);
        }
      }, [goldEdit]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setGold({ ...gold, [name]: value })
  }

  
  const adGold = async (e) => {
    e.preventDefault()
    setIsLoading(true);
    try {
      await addDoc(collection(db,"golds"), {
        ...gold,
        createdAt: Timestamp.now().toDate(),
       
      });
      setIsLoading(false);
      setUploadProgress(0);
      setGold(initialState);
      toast.success("Price uploaded successfully");
      navigate("/admin/all-golds");
    } catch (error) {
       setIsLoading(false);       
       toast.error(error.message);
     }
  }
  const editGold = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      try {
        const goldRef = doc(db, "golds", id);
        await updateDoc(goldRef, {...gold,
          // updatedAt: Timestamp.now().toDate(),
          updatedAt: Timestamp.now().toDate().toISOString(), // Convert to ISO
        });
        setIsLoading(false);
        toast.success("Price updated successfully");
        navigate("/admin/all-golds");
      } catch (error) {
        setIsLoading(false);
        toast.error(error.message);
      }
    };
  
  function detectForm(id, f1, f2) {
      return id === "ADD" ? f1 : f2;
    }
  
    return (
      <>
      {isLoading && <Loader />}
        <section className='container1'>
          <div className={styles.price}>
          <h2 className="h1">{detectForm(id, "ADD New Price", 
              "Edit Price")}</h2>
          <Card cardClass={styles.card}>
          <form onSubmit={detectForm(id, adGold, editGold )}>
       
        <label>Deed</label>
          <input type='text'
          placeholder=' deed'
          required
          name='deed'
          value={gold?.deed}
          onChange={(e)=>handleInputChange(e)}
          />
          <input type='text'
          placeholder=' pay'
          required
          name='pay'
          value={gold?.pay}
          onChange={(e)=>handleInputChange(e)}
          />
          <input type='text'
          placeholder='order'
          required
          name='order'
          value={gold?.order}
          onChange={(e)=>handleInputChange(e)}
          />
            <input type='text'
          placeholder=' photo'
          required
          name='photo'
          value={gold?.photo}
          onChange={(e)=>handleInputChange(e)}
          />
            <input type='text'
          placeholder=' vit'
          required
          name='vit'
          value={gold?.vit}
          onChange={(e)=>handleInputChange(e)}
          />
           <input type='text'
          placeholder=' orbit'
          required
          name='orbit'
          value={gold?.orbit}
          onChange={(e)=>handleInputChange(e)}
          />
        <button className='--btn --btn-primary'>
          {detectForm(id, "Save Price", "Edit Price")}
        </button>
          </form>
          
          </Card>
          </div>
          </section>
      </>
    )
  }

export default AddGold