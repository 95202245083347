import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import styles from "./AddAbout.module.scss"
import Card from '../../card/Card';
import { useSelector } from 'react-redux';
import { selectAbouts } from '../../../redux/slice/aboutSlice';
import { db, storage } from '../../../firebase/Config';
import { toast } from 'react-toastify';
import Loader from '../../loader/Loader';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { addDoc, collection, doc, Timestamp, updateDoc } from 'firebase/firestore';


const initialState = {
    header: "",
    imageURL: "",
    body: "",
  };
  
  const AddAbout = () => {
      const {id} = useParams()
      const abouts = useSelector(selectAbouts);
      const aboutEdit = abouts.find((item) => item.id === id);
    
  
      const [about, setAbout] = useState(() =>{
        const newState = detectForm(id, 
            {...initialState},
            aboutEdit
        )
        return newState
     })
  

  
      const [uploadProgress, setUploadProgress] = useState(0)
  
      const [isLoading, setIsLoading] = useState(false)
      const navigate = useNavigate()
  
      useEffect(() => {
        if (aboutEdit) {
          setAbout(aboutEdit);
        }
      }, [aboutEdit]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setAbout({ ...about, [name]: value })
  }
  
  const handleImageChange = (e) => {
    const file = e.target.files[0]
    console.log(file);
    
    const storageRef = ref(storage, `abouts/${Date.now()}${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        toast.error(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setAbout({ ...about, imageURL: downloadURL });
          toast.success("Image uploaded successfully");
        });
      }
    );
  }
  
  const addAbout = async (e) => {
    e.preventDefault()
    setIsLoading(true);
    try {
      await addDoc(collection(db,"abouts"), {
        ...about,
        createdAt: Timestamp.now().toDate(),
       
      });
      setIsLoading(false);
      setUploadProgress(0);
      setAbout(initialState);
      toast.success("About uploaded successfully");
      navigate("/admin/all-abouts");
    } catch (error) {
       setIsLoading(false);       
       toast.error(error.message);
     }
  }
  const editAbout = async (e) => {
      e.preventDefault();
      setIsLoading(true);
     
      if (about?.imageURL !== aboutEdit?.imageURL) {
        const storageRef = ref(storage, aboutEdit.imageURL);
        deleteObject(storageRef); 
      }
  
      try {
        const aboutRef = doc(db, "abouts", id);
        await updateDoc(aboutRef, {
          ...about,
          // updatedAt: Timestamp.now().toDate(),
          updatedAt: Timestamp.now().toDate().toISOString(), // Convert to ISO
        });
        setIsLoading(false);
        toast.success("About updated successfully");
        navigate("/admin/all-abouts");
      } catch (error) {
        setIsLoading(false);
        toast.error(error.message);
      }
  
    };
  
  
  function detectForm(id, f1, f2) {
      return id === "ADD" ? f1 : f2;
    }
  
  
    return (
      <>
      {isLoading && <Loader />}
        <section className='container1'>
          <div className={styles.about}>
          <h2 className="h1">{detectForm(id, "ADD New About", 
              "Edit About")}</h2>
          <Card cardClass={styles.card}>
          <form onSubmit={detectForm(id, addAbout, editAbout)}>
          <label>About Image:</label>
          <Card cardClass={styles.group}>
            {uploadProgress === 0 ? null : (
                <div className={styles.progress}>
  
                <div className={styles["progress-bar"]} 
                style={{width: `${uploadProgress}%`}}>
                 {uploadProgress < 100 ? `uploading 
                 ${uploadProgress}%` : `upload complete ${uploadProgress}%`}
                  </div>
                </div> 
            )}
         
  
            <input type='file'
             accept='image/*'
          placeholder='About Image'
          name='image'
          // value={parent.imageURL}
          onChange={(e)=>handleImageChange(e)}
          />
          {about?.imageURL === "" ? null : (
           <input 
           type='text'
           required
           placeholder='Image URL'
           name='imageURL'
           value={about?.imageURL}
           disabled
           />
          )}
          </Card>
        <label>About</label>
          <input type='text'
          placeholder=' header'
          required
          name='header'
          value={about?.header}
          onChange={(e)=>handleInputChange(e)}
          />
       
        <label>Body</label>
        <textarea
        name='body'
        required
        value={about?.body}
        onChange={(e)=>handleInputChange(e)}
        cols="10" rows="10">
        </textarea>
        <button className='--btn --btn-primary'>
          {detectForm(id, "Save About", "Edit About")}
        </button>
          </form>
          </Card>
          </div>
          </section>
      </>
    )
  }
  


export default AddAbout