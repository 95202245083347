
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/home/Home'
import NotFound from './pages/notFound/NotFound'
import Footer from './components/footer/Footer'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/header/Header'
import Admin from './pages/admin/Admin'
import Reset from './pages/auth/Reset'
import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import Contact from './pages/contact/Contact'



const App = () => {
  return (
    <>
    <BrowserRouter>
    <ToastContainer />
    <Header />
    <Routes>
    <Route path='/' element={<Home />}/>
    <Route path='/contact' element={<Contact />}/> 
    <Route path='/login' element={<Login />}/> 
    <Route path='/register' element={<Register/>}/>
    <Route path='/reset' element={<Reset />}/>
    <Route path='/admin/*' element={<Admin />}/> 
    <Route path='*' element={<NotFound />}/>
    </Routes>
    <Footer />
    </BrowserRouter>
    </>
  )
}

export default App
// const App = () => {

//   return (
//     <>
//   <BrowserRouter>
//   <ToastContainer />
//   <Header />
//  <Routes>
//  <Route path='/' element={<Home />}/>
//  <Route path='/login' element={<Login />}/>
//  <Route path='/register' element={<Register />}/>
//  <Route path='/reset' element={<Reset />}/> 
//  <Route path='/admin/*' element={<AdminOnlyRoute><Admin /></AdminOnlyRoute>}/>
//  <Route path='/product-details/:id' element={<ProductDetails />}/> 
//  <Route path='/photo-details/:id' element={<PhotoDetails />}/> 
//  <Route path='/info-details/:id' element={<InfoDetails />}/> 
//  <Route path='/comment-info/:id' element={<InfoComment />}/>
//  <Route path='/review-product/:id' element={<ReviewProducts />}/>  
//  <Route path='/parent-bio/:id' element={<ParentBio />}/> 
//  <Route path='/Grannie-bio/:id' element={<GrannieBio />}/> 
//  <Route path='/Relative-bio/:id' element={<RelativeBio />}/> 
//  <Route path='/Passed-bio/:id' element={<PassedBio />}/> 
//  <Route path='/comment-photo/:id' element={<PhotoComment />}/> 
//  <Route path='/tribute-passed/:id' element={<Tribute />}/> 

//  <Route path='*' element={<NotFound />}/> 

 
//  </Routes>
//  <Footer />
//  </BrowserRouter>
//     </>

//   )
// }

// export default App
