import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 abouts: [],
}

const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    STORE_ABOUTS: (state, action) => {
      state.abouts = action.payload.abouts;
    },

  },

});


export const {STORE_ABOUTS} = aboutSlice.actions;

export const selectAbouts = (state) => state.about.abouts;


export default aboutSlice.reducer

