import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../firebase/Config";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";


const useFetchCollection = (collectionName) => {
 const [data, setData] = useState([])
 const [isLoading, setIsLoading] = useState(false)

    const getCollection = async () => {
        setIsLoading(true);
        try {
          const docsRef = collection(db, collectionName);
          const q = query(docsRef, orderBy("createdAt", "desc"));
          onSnapshot(q, (snapshot) => {
        
            const allData = snapshot.docs.map((doc) => {
              const data = doc.data();
              return {
                id: doc.id,
                ...data,
              
              };
            });
        //  console.log(allData);
         
            setData(allData);
            setIsLoading(false);
        
          });
        } catch (error) {
          setIsLoading(false);
          toast.error(error.message);
        }
      };
      
      useEffect(() => {
       getCollection()
      }, [])

      return {data, isLoading}
}

export default useFetchCollection;