import {configureStore, combineReducers} from "@reduxjs/toolkit"
import authSlice from "./slice/authSlice";
import aboutSlice from "./slice/aboutSlice";
import productSlice from "./slice/productSlice";
import yoofSlice from "./slice/yoofSlice";
import cowrieSlice from "./slice/cowrieSlice";
import goldSlice from "./slice/goldSlice";



const rootReducer = combineReducers({
    auth: authSlice,
    about: aboutSlice,
    product: productSlice,
    cowrie: cowrieSlice,
    gold: goldSlice,
    yoof: yoofSlice,
})

 const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
        }),

})




export default store;