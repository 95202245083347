import React from 'react'
import styles from "./AboutItem.module.scss"

const AboutItem = ({ header, imageURL, body}) => {
 
  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = 
      text.substring(0, n).concat("...")
      return shortenedText;
    }
    return text
  }
  return (
    <>
        <div className= {styles.title}>
            <h1 className={styles["s-head"]}>
               {header}
            </h1>
        </div>

        <div className={styles.about}>
            <div className= {styles["about-text"]}>
                <h4 className={styles["u-mb-medium"]}>
                   {shortenText(body, 800)}
                </h4>
            </div>
            <div className={styles["about-image"]}>
                <img src={imageURL}  alt="header"/>
            </div>
        </div>
    </>
  )
}

export default AboutItem