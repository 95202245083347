import { useDispatch, useSelector } from "react-redux";
import useFetchCollection from "../../customHooks/useFetchCollection";
import { selectProducts, STORE_PRODUCTS } from "../../redux/slice/productSlice";
import { useEffect } from "react";
import ProductList from "./productList/ProductList";




const Product = () => {
    const {data} = useFetchCollection("products")
    const products = useSelector(selectProducts)
  
       const dispatch = useDispatch()
  
       useEffect(() => {
         dispatch(STORE_PRODUCTS({products: data}))
       }, [data, dispatch])
       
    return (
      <div >
       <ProductList products={products}/>
      </div>
    )
  }

export default Product