import { FaUserCircle } from "react-icons/fa"
import styles from "./Navbar.module.scss"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { selectUserName } from "../../../redux/slice/authSlice"


const activeLink = ({isActive}) => (
    isActive ? `${styles.active}` : "")

const Navbar = () => {
  const userName = useSelector(selectUserName)

  return (
    <div className={styles.navbar}>
        <div className={styles.user}>
        <FaUserCircle size={40} color="#fff"/>
        <h4> {userName}</h4>
       
        </div>
        <nav>
        <ul>
            <li>
                <NavLink to="/admin/home" className={activeLink}>
                    Home
                </NavLink>
                
            </li>
            <li>
                <NavLink to="/admin/all-abouts" className={activeLink}>
                    View About
                </NavLink>
            </li> 
            <li>
                <NavLink to="/admin/add-about/ADD" className={activeLink}>
                    Add About
                </NavLink>
            </li>
            <li>
                <NavLink to="/admin/all-products" className={activeLink}>
                    View Products
                </NavLink>
            </li> 
            <li>
                <NavLink to="/admin/add-product/ADD" className={activeLink}>
                    Add Product
                </NavLink>
            </li>
            <li>
                <NavLink to="/admin/all-cowries" className={activeLink}>
                    View Cowries
                </NavLink>
            </li> 
            <li>
                <NavLink to="/admin/add-cowrie/ADD" className={activeLink}>
                    Add Cowrie
                </NavLink>
            </li>
            <li>
                <NavLink to="/admin/all-golds" className={activeLink}>
                    View Golds
                </NavLink>
            </li> 
            <li>
                <NavLink to="/admin/add-gold/ADD" className={activeLink}>
                    Add Gold
                </NavLink>
            </li>

            <li>
                <NavLink to="/admin/all-yoofs" className={activeLink}>
                    View Yoofprice
                </NavLink>
            </li> 
            <li>
                <NavLink to="/admin/add-yoof/ADD" className={activeLink}>
                    Add Yoofprice
                </NavLink>
            </li>
        </ul>
        </nav>
    </div>
  )
}

export default Navbar