import { collection, deleteDoc, doc, onSnapshot, orderBy, query } from "firebase/firestore"
import Notiflix from "notiflix"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { db } from "../../../firebase/Config"
import Loader from "../../loader/Loader"
import styles from "./ViewProducts.module.scss"
import { STORE_PRODUCTS } from "../../../redux/slice/productSlice"
import { Link } from "react-router-dom"
import { FaEdit, FaTrashAlt } from "react-icons/fa"


const ViewProducts = () => {
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  
  const dispatch = useDispatch()
  
  useEffect(() => {
    getProducts()
  }, [])
  
  const getProducts = async () => {
    setIsLoading(true)
    try {
      const productsRef = collection(db, "products")
      const q = query(productsRef, orderBy("createdAt", "desc"))
      onSnapshot(q, (snapshot) => {
        // console.log(snapshot.docs);
        const allProducts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        // console.log(allProducts);
        setProducts(allProducts)
        setIsLoading(false)
        dispatch(
          STORE_PRODUCTS({
            products: allProducts,
          })
        )
      })
    } catch (error) {
      setIsLoading(false)
      toast.error(error.message)
    }
  }
  
  const confirmDelete = (id) => {
    Notiflix.Confirm.show(
      'Delete product',
      'You are about to delete this Product?',
      'Delete',
      'Cancel',
      () => deleteProduct(id),
      () => console.log("Delete canceled"),
      {
        width: '220px',
        borderRadius: '4px',
        titleColor: "orangered",
        okButtonBackground: "orangered",
        cssAnimationStyle: "zoom",
      }
    )
  };
  
  
  const deleteProduct = async (id) => {
  try {
    await deleteDoc(doc(db, "products", id));
    toast.success("Product deleted successfully");
  } catch (error) {
    toast.error(error.message); 
  }
  }
  
    return (
      <>
       {isLoading && <Loader />}
        <div className={styles.table}>
       <h2>Products</h2>
       {products.length === 0 ? (
        <p>No Product</p>
      ) :(
        <table>
          <thead>
                <tr>
                  <th>s/n</th>
                  <th>Title</th>
                  <th>Body</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {products?.map((product, index) => {
                  const { id, title, body } = product;
                  return (
                    <tr key={id}>
                      <td>{index + 1}</td>
                      <td>{title}</td>
                      <td>{body}</td>
                      <td className={styles.icons}>
                        <Link to={`/admin/add-product/${id}`}>
                          <FaEdit size={20} color="green" />
                        </Link>
                        &nbsp;
                        <FaTrashAlt size={18} color="red"  
                        onClick={() => confirmDelete(id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
          </table>
          )}
        </div>
      </>
    )
  }
export default ViewProducts