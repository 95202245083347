import { Link } from "react-router-dom"
import styles from "./NotFount.module.scss"

const NotFound = () => {
  return (
    <div className={styles["not-found"]}>
  <div>
    <h2>404</h2>
    <p>Page not found</p>
    <button className="--btn">
     <Link to="/">
     &larr; back to Home
     </Link>
    </button>
  </div>
    </div>
  )
}

export default NotFound