import React, { useEffect } from 'react'
import GoldList from './goldList/GoldList'
import { selectGolds, STORE_GOLDS } from '../../redux/slice/goldSlice'
import { useDispatch, useSelector } from 'react-redux'
import useFetchCollection from '../../customHooks/useFetchCollection'


const Gold = () => {
    const {data} = useFetchCollection("golds")
    const golds = useSelector(selectGolds)
  
    const dispatch = useDispatch()
   
       useEffect(() => {
        dispatch(STORE_GOLDS({golds: data}))
      }, [data, dispatch])
      
    return (
      <div >
       <GoldList golds={golds}/>
      </div>
    )
  }

export default Gold