import { useSelector } from 'react-redux'
import { selectIsLoggedIn } from '../../redux/slice/authSlice';


const ShowOnloggedIn = ({children}) => {
 const isLoggedIn = useSelector(selectIsLoggedIn)
   
 if (isLoggedIn) {
    return children;
 } else {
 return null;
 }
}

export const ShowOnloggedOut = ({children}) => {
    const isLoggedIn = useSelector(selectIsLoggedIn)
      
    if (!isLoggedIn) {
       return children;
    } else {
    return null;
    }
   }

export default ShowOnloggedIn