import React, { useEffect, useState } from 'react'
import styles from './ViewGolds.module.scss'
import { Link } from 'react-router-dom'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { collection, deleteDoc, doc, onSnapshot, orderBy, query } from 'firebase/firestore'
import { db } from '../../../firebase/Config'
import { STORE_GOLDS } from '../../../redux/slice/goldSlice'
import { toast } from 'react-toastify'
import Notiflix from 'notiflix'
import Loader from '../../loader/Loader'

const ViewGolds = () => {
    const [golds, setGolds] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    
    const dispatch = useDispatch()
    
    useEffect(() => {
      getGolds()
    }, [])
    
    const getGolds = async () => {
      setIsLoading(true)
      try {
        const goldsRef = collection(db, "golds")
        const q = query(goldsRef, orderBy("createdAt", "desc"))
        onSnapshot(q, (snapshot) => {
          // console.log(snapshot.docs);
          const allGolds = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          // console.log(allCories);
          setGolds(allGolds)
          setIsLoading(false)
          dispatch(
            STORE_GOLDS({
              golds: allGolds,
            })
          )
        })
      } catch (error) {
        setIsLoading(false)
        toast.error(error.message)
      }
    }
    
    const confirmDelete = (id) => {
      Notiflix.Confirm.show(
        'Delete gold',
        'You are about to delete this cowrie?',
        'Delete',
        'Cancel',
        () => deleteGold(id),
        () => console.log("Delete canceled"),
        {
          width: '220px',
          borderRadius: '4px',
          titleColor: "orangered",
          okButtonBackground: "orangered",
          cssAnimationStyle: "zoom",
        }
      )
    };
    
    
    const deleteGold = async (id) => {
    try {
      await deleteDoc(doc(db, "golds", id));
      toast.success("Gold deleted successfully");
    } catch (error) {
      toast.error(error.message); 
    }
    }
    
      return (
        <>
         {isLoading && <Loader />}
          <div className={styles.table}>
         <h2>Price</h2>
         {golds.length === 0 ? (
          <p>No Price</p>
        ) :(
          <table>
            <thead>
                  <tr>
                    <th>s/n</th>
                    <th>Plan</th>
                    <th>Fee</th>
                    <th>Custom</th>
                    <th>Picture</th>
                    <th>Vid</th>
                    <th>Credit</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {golds?.map((gold, index) => {
                    const { id, deed, pay, order, photo, vit, orbit} = gold;
                    return (
                      <tr key={id}>
                        <td>{index + 1}</td>
                        <td>{deed}</td>
                        <td>{pay}</td>
                        <td>{order}</td>
                        <td>{photo}</td>
                        <td>{vit}</td>
                        <td>{orbit}</td>
                        <td className={styles.icons}>
                          <Link to={`/admin/add-gold/${id}`}>
                            <FaEdit size={20} color="green" />
                          </Link>
                          &nbsp;
                          <FaTrashAlt size={18} color="red"  
                          onClick={() => confirmDelete(id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
            </table>
            )}
          </div>
        </>
      )
    }

export default ViewGolds