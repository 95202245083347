import { createSlice } from '@reduxjs/toolkit'

const initialState = {
cowries: [],
}

const cowrieSlice = createSlice({
  name: 'cowrie',
  initialState,
  reducers: {
  STORE_COWRIES: (state, action) => {
    console.log(action.payload);
    state.cowries = action.payload.cowries
  }  
  }
});

export const {STORE_COWRIES} = cowrieSlice.actions
export const selectCowries = (state) => state.cowrie.cowries

export default cowrieSlice.reducer