
import { Link, NavLink } from "react-router-dom"
import styles from "./Header.module.scss"
import { FaTimes, FaUserCircle } from "react-icons/fa"
import { onAuthStateChanged, signOut } from "firebase/auth"
import { auth } from "../../firebase/Config"
import { useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { REMOVE_ACTIVE_USER, SET_ACTIVE_USER } from "../../redux/slice/authSlice"
import { toast } from "react-toastify"
import {HiOutlineMenuAlt2} from "react-icons/hi"
import ShowOnloggedIn, { ShowOnloggedOut } from "../hiddenLink/HiddenLink"
import {AdminOnlyLink} from "../adminOnlyRoute/AdminOnlyRoute"


const logo =(
    <div className={styles.logo}>
    <Link to="/">
    <h2>
     Nex<span>.</span>leap
    </h2>
    </Link>
   </div>
)



const activeLink = ({isActive}) => (
    isActive ? `${styles.active}` : "")

const Header = () => {
const [showMenu, setShowMenu] = useState(false)
const [displayName, setDisplayName] = useState("")
const [scrollPage, setScrollPage] = useState(false)

const navigate = useNavigate();

const dispatch = useDispatch();

const fixNavbar = () => {
   if (window.scrollY > 50) {
    setScrollPage(true)
   } else {
    setScrollPage(false)
   }
};
window.addEventListener("scroll", fixNavbar)

//Monitor currently signed in user
useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // console.log(user);
    if (user.displayName == null) {
        const u1 = user.email.substring(0, user.email.indexOf("@"));
        const uName = u1.charAt(0).toUpperCase() + u1.slice(1)
        setDisplayName(uName)
    } else {
     setDisplayName(user.displayName)
    }

     dispatch(SET_ACTIVE_USER({
        email: user.email,
        userName: user.displayName ? user.displayName : displayName,
        userID: user.uid,
     }))
        } else {
      setDisplayName("")
      dispatch(REMOVE_ACTIVE_USER())
        }
      });
}, [dispatch, displayName])

const toggleMenu = () => {
    setShowMenu(!showMenu)
}

const hideMenu = () => {
    setShowMenu(false)
}

const logoutUser = () => {

    signOut(auth).then(() => {
        toast.success("Logout Successfull")
        navigate("/")
      }).catch((error) => {
       toast.error(error.message)
      });
}

  return (
<header className={scrollPage ? `${styles.fixed}` : null}>
    <div className= {styles.header}>
    {logo}
    <nav className={
        showMenu ? `${styles["show-nav"]}` : `${styles["hide-nav"]}` }>
        <div   className={
            showMenu ? `${styles["nav-wrapper"]} ${styles["show-nav-wrapper"]}`
        : `${styles["nav-wrapper"]}`}
        onClick={hideMenu}></div>
      
        <ul  onClick={hideMenu} >
        <li className={styles["logo-mobile"]}>
            {logo}
           <FaTimes size={22} color='#fff' 
           onClick={hideMenu}/>
         
         </li>
         <li>
            <AdminOnlyLink> 
            <Link to="/admin/add-product/ADD" > 
                <button className='btn '>
                Admin
                </button>
                 </Link>
            </AdminOnlyLink>
         </li>
         <li>
            <NavLink to="/" > Home </NavLink> 
         </li>
         <li>
            <NavLink to="/contact" >
                Subscribe
            </NavLink>
         </li>
        
        </ul>
        <div className={styles["header-right"]} 
        onClick={hideMenu}>
         <span className={styles.links}>
            <ShowOnloggedOut> 
            <NavLink to="/login" className={activeLink}>
                Login
            </NavLink>
            </ShowOnloggedOut>
            <ShowOnloggedIn> 
            <a href="#home" style={{color: "#2a8a86"}}>
              <FaUserCircle size={16} />
              Hi, {displayName}
            </a>
            </ShowOnloggedIn>
            <ShowOnloggedIn>
            <NavLink to="/" onClick={logoutUser}>
                Logout
            </NavLink>
            </ShowOnloggedIn>
         </span>
        </div>
    </nav>
    <div className={styles["menu-icon"]}>
     <HiOutlineMenuAlt2 size={28} onClick={toggleMenu}/>
    </div>
    </div>

</header>
  )
}

export default Header

