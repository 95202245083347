import React from 'react'
import CowrieItem from '../cowrieItem/CowrieItem'
import { useSelector } from 'react-redux'
import { selectCowries } from '../../../redux/slice/cowrieSlice'
const CowrieList = () => {

    const cowries = useSelector(selectCowries)
    
      return (
        <> 
        <div> 
            {cowries?.map((cowrie) => {
                return (
                   <div key={cowrie.id}>
                 <CowrieItem {...cowrie} 
                 cowrie = {cowrie} />
                 </div> 
                )
            }   
            )}
            </div>
        </>
      )
    }

export default CowrieList