import React from 'react'
import YoofItem from '../yoofItem/YoofItem'
import { useSelector } from 'react-redux'
import { selectYoofs } from '../../../redux/slice/yoofSlice'

const YoofList = () => {
    const yoofs = useSelector(selectYoofs)
    
    
      return (
        <> 
       
        <div> 
            {yoofs?.map((yoof) => {
                return (
                   <div key={yoof.id}>
                 <YoofItem {...yoof} 
                 yoof = {yoof} />
                 </div> 
                )
            }
                
            )}
            </div>
            {/* </div> */}
        </>
      )
    }

export default YoofList