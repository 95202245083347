import { createSlice } from '@reduxjs/toolkit'

const initialState = {
yoofs: [],
}

const yoofSlice = createSlice({
  name: "yoof",
  initialState,
  reducers: {

    STORE_YOOFS: (state, action) => {
        console.log(action.payload);  
        state.yoofs = action.payload.yoofs;
      },
  }
});

export const {STORE_YOOFS} = yoofSlice.actions

export const selectYoofs = (state) => state.yoof.yoofs;

export default yoofSlice.reducer
