import { createSlice } from '@reduxjs/toolkit'

const initialState = {
golds: [],
}

const goldSlice = createSlice({
  name: "gold",
  initialState,
  reducers: {
    STORE_GOLDS: (state, action) => {
      console.log(action.payload)
      state.golds = action.payload.golds
    }
  }
});

export const {STORE_GOLDS} = goldSlice.actions
export const selectGolds = (state) => state.gold.golds

export default goldSlice.reducer