import styles from './ViewAbout.module.scss'
import { collection, deleteDoc, doc, onSnapshot, orderBy, query } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { db, storage } from '../../../firebase/Config'
import { toast } from 'react-toastify'
import { STORE_ABOUTS } from '../../../redux/slice/aboutSlice'
import Notiflix from 'notiflix'
import { deleteObject, ref } from 'firebase/storage'
import Loader from '../../loader/Loader'
import { Link } from 'react-router-dom'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'

const ViewAbout = () => {
  const [abouts, setAbouts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  
  const dispatch = useDispatch()
  
  useEffect(() => {
    getAbouts()
  }, [])
  
  const getAbouts = async () => {
    setIsLoading(true)
    try {
      const aboutsRef = collection(db, "abouts")
      const q = query(aboutsRef, orderBy("createdAt", "desc"))
      onSnapshot(q, (snapshot) => {
        // console.log(snapshot.docs);
        const allAbouts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        // console.log(allAbouts);
        setAbouts(allAbouts)
        setIsLoading(false)
        dispatch(
          STORE_ABOUTS({
            abouts: allAbouts,
          })
        )
      })
    } catch (error) {
      setIsLoading(false)
      toast.error(error.message)
    }
  }
  
  const confirmDelete = (id, imageURL) => {
    Notiflix.Confirm.show(
      'Delete about',
      'You are about to delete this about?',
      'Delete',
      'Cancel',
      () => deleteAbout(id, imageURL),
      () => console.log("Delete canceled"),
      {
        width: '220px',
        borderRadius: '4px',
        titleColor: "orangered",
        okButtonBackground: "orangered",
        cssAnimationStyle: "zoom",
      }
    )
  };
  
  
  const deleteAbout = async (id, imageURL) => {
  try {
    await deleteDoc(doc(db, "abouts", id));
    const storageRef = ref(storage, imageURL);
    await deleteObject(storageRef);
    toast.success("About deleted successfully");
  } catch (error) {
    toast.error(error.message); 
  }
  }
  
    return (
      <>
       {isLoading && <Loader />}
        <div className={styles.table}>
       <h2>About</h2>
       {abouts.length === 0 ? (
        <p>No About</p>
      ) :(
        <table>
          <thead>
                <tr>
                  <th>s/n</th>
                  <th>Image</th>
                  <th>Header</th>
                  <th>Body</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {abouts.map((about, index) => {
                  const { id, imageURL, header, body } = about;
                  return (
                    <tr key={id}>
                      <td>{index + 1}</td>
                      <td>
                       <img src={imageURL} alt={header}
                       style={{'borderRadius': "50%", width: "80px", height: "80px"}}
                         />
                      </td>
                      <td>{header}</td>
                      <td>{body}</td>
                      <td className={styles.icons}>
                        <Link to={`/admin/add-about/${id}`}>
                          <FaEdit size={20} color="green" />
                        </Link>
                        &nbsp;
                        <FaTrashAlt size={18} color="red"  
                        onClick={() => confirmDelete(id, imageURL)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
          </table>
          )}
        </div>
      </>
    )
  }

export default ViewAbout