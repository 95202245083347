import { useSelector } from "react-redux"
import ProductItem from "../productItem/ProductItem"
import { selectProducts } from "../../../redux/slice/productSlice"
import { useState } from "react"
import styles from "./ProductList.module.scss"



const ProductList = () => {
  const [grid] = useState(true)
  const products = useSelector(selectProducts)
    
    
      return (
        <> 
         <div className={styles.title}>
        <h2 className={styles["s-head"]}>
           Our Products & Services
         </h2>
          </div>
        <div> 
          <div className={styles.grid}>
            {products.map((product) => {
                return (
                   <div key={product.id}>
                 <ProductItem {...product}  grid = {grid}
                 product = {product} />
                 </div> 
                )
            }
                
            )}
            </div>
            </div>
        </>
      )
    }

export default ProductList