import React, { useEffect } from 'react'
import CowrieList from './cowrieList/CowrieList'
import useFetchCollection from '../../customHooks/useFetchCollection'
import { useDispatch, useSelector } from 'react-redux'
import { selectCowries, STORE_COWRIES } from '../../redux/slice/cowrieSlice'

const Cowrie = () => {
    const {data} = useFetchCollection("cowries")
    const cowries = useSelector(selectCowries) 
    const dispatch = useDispatch()
  
       useEffect(() => {
         dispatch(STORE_COWRIES({cowries: data}))
       }, [data, dispatch])
       
    
      
    return (
      <div >
       <CowrieList cowries={cowries}/>
      </div>
    )
  }

export default Cowrie