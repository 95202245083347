import React from 'react'
import styles from './ProductItem.module.scss'
import { FaDesktop } from 'react-icons/fa'
import Card from '../../card/Card'


const ProductItem = ({ header, title, body}) => {
    
    const shortenText = (text, n) => {
      if (text.length > n) {
        const shortenedText = 
        text.substring(0, n).concat("...")
        return shortenedText;
      }
      return text
    }
    return (
      <>
      <Card cardClass={styles.product}> 
          <div >
              <div className= {styles["product-box"]}>
                <FaDesktop size={25} color='red'/>
                  <h3 className={styles["t-head"]}>
                     {shortenText(title, 100)}
                  </h3>
                  <p>
                    {shortenText(body, 400)}
                </p>
              </div>
          </div>
          </Card>
      </>
    )
  }

export default ProductItem