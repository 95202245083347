import React from 'react'
import styles from './YoofItem.module.scss'
import { Link } from 'react-router-dom'


const YoofItem = ({ sun, mercury, venus, earth, jupiter, mars }) => {
    return (
      <>
       <div className={styles["price-box"]}>
          <div className={styles.box3}>
             <p>{sun}</p>
             <h4 className={styles.cc}>{mercury}</h4>
          </div>
  
          <div className={styles["price-list"]}>
           <ul>
             <li>{venus}</li>
             <li>{earth}</li> 
             <li>{jupiter}</li>
             <li>{mars}</li>
           </ul>
           <Link to="/contact" className={styles.btn3}>Subscribe</Link>
          </div>
         </div>
      </>
    )
  }

export default YoofItem