import React from 'react'
import styles from "./Footer.module.scss"

const Footer = () => {
  return (
    <footer className={styles["footer-section"]}>
    <div className={`container ${styles.footer}`}>


        <div className={styles["footer-menu"]}>
            <p className={styles["u-mb-small"]}>Famiweb</p>
            <ul className={styles["footer-links"]}>
                <li>Family Website</li>
                <li>Secure</li>
                <li>Accessible Only by Family</li>
            </ul>
        </div>

        <div className={styles["footer-menu"]}>
            <p className={styles["u-mb-small"]}>Yoof</p>
            <ul className={styles["footer-links"]}>
                <li>Youth Group Connect</li>
                <li>Closed members Network</li>
                <li>Secure</li>
            </ul>
        </div>

        <div className={styles["footer-menu"]}>
            <p className={styles["u-mb-small"]}>Websites</p>
            <ul className={styles["footer-links"]}>
                <li>  Development</li>
                <li> Maintenance</li>
                <li> Management</li>
            </ul>
        </div>

     
       <div className="logo">
        <h2>Nexleap<span>Solutions</span></h2> 
        <p>&copy; 2024. All Rights Reserved</p> 
    </div>
    </div>
</footer>
  )
}

export default Footer


// import React from 'react'
// import styles from "./Footer.module.scss"


// const date = new Date()
// const year = date.getFullYear()

// const Footer = () => {
//   return (
//    <footer className={styles.footer}>
//     <div>
//    <p> &copy; {year} All rights reserved</p> 
//     </div>
// <div>
//   <small>Nextleap Solutions</small>
// </div>
//    </footer>
//   )
// }

// export default Footer