import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectYoofs } from '../../../redux/slice/yoofSlice';
import { addDoc, collection, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/Config';
import Loader from '../../loader/Loader';
import styles from "./AddYoofPrice.module.scss"
import Card from '../../card/Card';



const initialState = {
    sun: "",
    mercury: "",
    venus: "",
    earth: "",
    jupiter: "",
    mars: "",
  };
  
  const AddYoofPrice = () => {
      const {id} = useParams()
      const yoofs = useSelector(selectYoofs);
      const yoofEdit = yoofs?.find((item) => item.id === id || initialState);
      const [yoof, setYoof] = useState(() => detectForm(id, initialState, yoofEdit));
  
      const [setUploadProgress] = useState(0)
      const [isLoading, setIsLoading] = useState(false)
      const navigate = useNavigate()
  
      useEffect(() => {
        if (yoofEdit) {
          setYoof(yoofEdit);
        }
      }, [yoofEdit]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setYoof({ ...yoof, [name]: value })
  }

  
  const adPrice = async (e) => {
    e.preventDefault()
    setIsLoading(true);
    try {
      await addDoc(collection(db,"yoofs"), {
        ...yoof,
        createdAt: Timestamp.now().toDate(),
       
      });
      setIsLoading(false);
      setUploadProgress(0);
      setYoof(initialState);
      toast.success("Yoof uploaded successfully");
      navigate("/admin/all-yoofs");
    } catch (error) {
       setIsLoading(false);       
       toast.error(error.message);
     }
  }
  const editPrice = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      try {
        const yoofRef = doc(db, "yoofs", id);
        await updateDoc(yoofRef, {...yoof,
          // updatedAt: Timestamp.now().toDate(),
          updatedAt: Timestamp.now().toDate().toISOString(), // Convert to ISO
        });
        setIsLoading(false);
        toast.success("Price updated successfully");
        navigate("/admin/all-pricesss");
      } catch (error) {
        setIsLoading(false);
        toast.error(error.message);
      }
  
    };
  
  function detectForm(id, f1, f2) {
      return id === "ADD" ? f1 : f2;
    }
  
    return (
      <>
      {isLoading && <Loader />}
        <section className='container1'>
          <div className={styles.price}>
          <h2 className="h1">{detectForm(id, "ADD New Price", 
              "Edit Price")}</h2>
          <Card cardClass={styles.card}>
          <form onSubmit={detectForm(id, adPrice, editPrice)}>
        <label>Title</label>
          <input type='text'
          placeholder=' sun'
          required
          name='sun'
          value={yoof?.sun}
          onChange={(e)=>handleInputChange(e)}
          />
          <input type='text'
          placeholder=' mercury'
          required
          name='mercury'
          value={yoof?.mercury}
          onChange={(e)=>handleInputChange(e)}
          />
          <input type='text'
          placeholder=' venus'
          required
          name='venus'
          value={yoof?.venus}
          onChange={(e)=>handleInputChange(e)}
          />
            <input type='text'
          placeholder=' earth'
          required
          name='earth'
          value={yoof?.earth}
          onChange={(e)=>handleInputChange(e)}
          />
            <input type='text'
          placeholder=' jupiter'
          required
          name='jupiter'
          value={yoof?.jupiter}
          onChange={(e)=>handleInputChange(e)}
          />
           <input type='text'
          placeholder=' mars'
          required
          name='mars'
          value={yoof?.mars}
          onChange={(e)=>handleInputChange(e)}
          />
        <button className='--btn --btn-primary'>
          {detectForm(id, "Save Price", "Edit Price")}
        </button>
          </form>
          </Card>
          </div>
          </section>
      </>
    )
  }

export default AddYoofPrice