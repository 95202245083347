import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectProducts } from "../../../redux/slice/productSlice";
import { addDoc, collection, doc, Timestamp, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase/Config";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";
import styles from "./AddProduct.module.scss"
import Card from "../../card/Card";


const initialState = {
    title: "",
    body: "",
  };
  
  const AddProduct = () => {
      const {id} = useParams()
      const products = useSelector(selectProducts);
      const productEdit = products?.find((item) => item.id === id);
    
  
      const [product, setProduct] = useState(() =>{
        const newState = detectForm(id, 
            {...initialState},
            productEdit
        )
        return newState
     })
  

  
      const [setUploadProgress] = useState(0)
  
      const [isLoading, setIsLoading] = useState(false)
      const navigate = useNavigate()
  
      useEffect(() => {
        if (productEdit) {
          setProduct(productEdit);
        }
      }, [productEdit]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setProduct({ ...product, [name]: value })
  }

  
  const adProduct = async (e) => {
    e.preventDefault()
    setIsLoading(true);
    try {
      await addDoc(collection(db,"products"), {
        ...product,
        createdAt: Timestamp.now().toDate(),
       
      });
      setIsLoading(false);
      setUploadProgress(0);
      setProduct(initialState);
      toast.success("Product uploaded successfully");
      navigate("/admin/all-products");
    } catch (error) {
       setIsLoading(false);       
       toast.error(error.message);
     }
  }
  const editProduct = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      try {
        const productRef = doc(db, "products", id);
        await updateDoc(productRef, {...product,
          // updatedAt: Timestamp.now().toDate(),
          updatedAt: Timestamp.now().toDate().toISOString(), // Convert to ISO
        });
        setIsLoading(false);
        toast.success("Product updated successfully");
        navigate("/admin/all-products");
      } catch (error) {
        setIsLoading(false);
        toast.error(error.message);
      }
  
    };
  
  function detectForm(id, f1, f2) {
      return id === "ADD" ? f1 : f2;
    }
  
    return (
      <>
      {isLoading && <Loader />}
        <section className='container1'>
          <div className={styles.product}>
          <h2 className="h1">{detectForm(id, "ADD New Product", 
              "Edit Product")}</h2>
          <Card cardClass={styles.card}>
          <form onSubmit={detectForm(id, adProduct, editProduct)}>
        <label>Title</label>
          <input type='text'
          placeholder=' title'
          required
          name='title'
          value={product?.title}
          onChange={(e)=>handleInputChange(e)}
          />
        <label>Body</label>
        <textarea
        name='body'
        required
        value={product?.body}
        onChange={(e)=>handleInputChange(e)}
        cols="10" rows="10">
        </textarea>
        <button className='--btn --btn-primary'>
          {detectForm(id, "Save Product", "Edit Product")}
        </button>
          </form>
          </Card>
          </div>
          </section>
      </>
    )
  }

export default AddProduct;