import React from 'react'
import styles from "./Home.module.scss"
import About from '../../components/about/About'
import Product from '../../components/product/Product'
import Card from '../../components/card/Card'
import Cowrie from '../../components/cowrie/Cowrie'
import Gold from '../../components/gold/Gold'
import Yoof from '../../components/yoof/Yoof'


const Home = () => {
  return (
    <>
    <section className={styles["intro-section"]}>
    <div className={`container ${styles["intro-content"]}`}>
        <div className={styles["intro-text"]}>
            <h1 className={styles["u-mb-small"]}>
              <span className={styles["first-span"]}>Welcome to Nexleap Solutions...</span>
                For Your Custom  <span className={styles["second-span"]}></span>
            </h1>
            <p className={styles["u-mb-medium"]}>
             Our products are thoughtfully designed to meet the unique needs of our valued clients and subscribers.
             We’re dedicated to delivering solutions that not only fulfill your expectations but exceed them. Our customer care
             team is available around the clock, 24/7, ready to provide swift, insightful responses to any inquiries you may have.
              Whether you're curious about our products or seeking more information on our services, we're here to guide you 
              every step of the way.
            </p>
            {/* <a href="*" className={styles["btn"]}>Get a quote</a> */}
        </div>
    </div>
    <div className={styles["video-container"]}>
        <div className={styles["video-overlay"]}></div>
        <video autoPlay loop muted>
            {/* <source src="./pastorchris.mp4" type="video/mp4"> */}
        </video> 
    </div> 
    </section>
    <section className={styles["about-section"]} id="about">
   <div className={styles.container}>
   <About/>
   </div>
    </section>
    <section className={styles["product-section"]}>
   <div className={styles.container}>
    <Product/>
    </div>
   </section>
   <section className="price-section" id="pricing">
   <div className="container">
   <div className={styles.title}>
   <h2 className={styles["s-head"]}>
       famiweb packages
    </h2>
    </div>
    <Card cardClass={styles.price}>
    <Cowrie />
    <Gold />
    <Yoof />
    </Card>
   
    </div>
   </section>  
      
    
    </>
  )
}

export default Home


