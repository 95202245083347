import styles from "./auth.module.scss"
import { Link } from 'react-router-dom'
import Card from "../../components/card/Card"
import { useState } from "react"
import { toast } from "react-toastify"
import Loader from "../../components/loader/Loader"
import { sendPasswordResetEmail } from "firebase/auth"
import { auth } from "../../firebase/Config"

const Reset = () => {
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const resetPassword = (e) => {
         e.preventDefault();
        setIsLoading(true)

        sendPasswordResetEmail(auth, email)
        .then(() => {
            setIsLoading(false)
        toast.success("Check your email")
        })
        .catch((error) => {
            setIsLoading(false)
        toast.error(error.message)
        });   
    }

  return (
    <> 
    {isLoading && <Loader /> }
    <section className={`container ${styles.auth}`}>
    <Card> 
    <div className={styles.form}>
    <h2>Reset</h2>
    <form
     onSubmit={resetPassword}
    >
      <input 
       type="text"
        placeholder='Email'
         required
         value={email}
         onChange={(e)=>setEmail(e.target.value)}
          />
       <button type="submit" className='--btn --btn-primary --btn-block'>Reset</button>
     <div className={styles.links}>
        <p>
        <Link to="/login">-Login</Link>
        </p>
        <p>
        <Link to="/register">-Register</Link>
        </p>
     </div>
    </form>
    </div>
    </Card> 
    </section>
    </>
  )
}

export default Reset