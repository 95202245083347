import React, { useEffect, useState } from 'react'
import styles from './ViewCowries.module.scss'
import { useDispatch } from 'react-redux'
import Loader from '../../loader/Loader'
import { Link } from 'react-router-dom'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { collection, deleteDoc, doc, onSnapshot, orderBy, query } from 'firebase/firestore'
import { db } from '../../../firebase/Config'
import Notiflix from 'notiflix'
import { STORE_COWRIES } from '../../../redux/slice/cowrieSlice'


const ViewCowries = () => {
    const [cowries, setCowries] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    
    const dispatch = useDispatch()
    
    useEffect(() => {
      getCowries()
    }, [])
    
    const getCowries = async () => {
      setIsLoading(true)
      try {
        const cowriesRef = collection(db, "cowries")
        const q = query(cowriesRef, orderBy("createdAt", "desc"))
        onSnapshot(q, (snapshot) => {
          // console.log(snapshot.docs);
          const allCories = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          // console.log(allCories);
          setCowries(allCories)
          setIsLoading(false)
          dispatch(
            STORE_COWRIES({
              cowries: allCories,
            })
          )
        })
      } catch (error) {
        setIsLoading(false)
        toast.error(error.message)
      }
    }
    
    const confirmDelete = (id) => {
      Notiflix.Confirm.show(
        'Delete cowrie',
        'You are about to delete this cowrie?',
        'Delete',
        'Cancel',
        () => deleteCowrie(id),
        () => console.log("Delete canceled"),
        {
          width: '220px',
          borderRadius: '4px',
          titleColor: "orangered",
          okButtonBackground: "orangered",
          cssAnimationStyle: "zoom",
        }
      )
    };
    
    
    const deleteCowrie = async (id) => {
    try {
      await deleteDoc(doc(db, "cowries", id));
      toast.success("Cowrie deleted successfully");
    } catch (error) {
      toast.error(error.message); 
    }
    }
    
      return (
        <>
         {isLoading && <Loader />}
          <div className={styles.table}>
         <h2>Price</h2>
         {cowries.length === 0 ? (
          <p>No Price</p>
        ) :(
          <table>
            <thead>
                  <tr>
                    <th>s/n</th>
                    <th>Plan</th>
                    <th>Fee</th>
                    <th>Custom</th>
                    <th>Picture</th>
                    <th>Vid</th>
                    <th>Credit</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {cowries?.map((cowrie, index) => {
                    const { id, plan, fee, custom, picture, vid, credit} = cowrie;
                    return (
                      <tr key={id}>
                        <td>{index + 1}</td>
                        <td>{plan}</td>
                        <td>{fee}</td>
                        <td>{custom}</td>
                        <td>{picture}</td>
                        <td>{vid}</td>
                        <td>{credit}</td>
                        <td className={styles.icons}>
                          <Link to={`/admin/add-cowrie/${id}`}>
                            <FaEdit size={20} color="green" />
                          </Link>
                          &nbsp;
                          <FaTrashAlt size={18} color="red"  
                          onClick={() => confirmDelete(id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
            </table>
            )}
          </div>
        </>
      )
    }

export default ViewCowries