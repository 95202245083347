import React, { useRef } from 'react';
import { FaEnvelope} from "react-icons/fa"
import Card from "../../components/card/Card"
import styles from "./Contact.module.scss"
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
    .sendForm(process.env.REACT_APP_EMJS_SERVICE_KEY, process.env.REACT_APP_EMJS_TEMPLATE_KEY, form.current, {
      publicKey: process.env.REACT_APP_EMJS_PUBLIC_KEY,
    })
    .then((result) => {
      toast.success("Message sent")
      },
      (error) => {
        toast.error(error.text)
      },
    );
    e.target.reset()
};

  return (
    <section>
  <div className={`container ${styles.contact}`}>
  <h2>Read the instructions before subscription</h2>
  <div className={styles.section}>
   <form ref={form} onSubmit={sendEmail}>
    <Card cardClass={styles.card}>
    <label>Name</label>
    <input 
    type="text" 
    name="user_name"
    placeholder="Full name"
    required
    />
     <label>Email</label>
    <input 
    type="email" 
    name="user_email"
    placeholder="Your Active Email"
    required
    />
     <label>Famiweb Plan</label>
    <input 
    type="text" 
    name="subject"
    placeholder="Chosen famiweb plan"
    required
    />
      <label>Message Box</label>
      <textarea name="message" cols="10"
      rows="10"></textarea>
      <button className="btn btn-danger btn-block">
       Send Subscription
      </button>
    </Card>
   </form>
   <div className={styles.detail}>
   <Card cardClass={styles.card2}>
   <h3>Instructions:</h3>
   <p>Provide the following info in the message box.</p> <hr />
    <div className={styles.icons}>
    <span>
      <FaEnvelope />
      <p>Your dedicated famiweb email address</p>
    </span>
    <hr />
    <span>
      <FaEnvelope />
      <p>At least two proposed website domain names <br/>
         (each  name not more than 12 characters)</p>
    </span>
    <hr />
    <span>
      <FaEnvelope />
      <p>Details of payment of <b>set-up fee</b> of $200 using prevailing <br/>
        BOG exchange rate to Momo number +233 245 970043. <br/>
        The details should include the exchange rate, name of <br/>
        sender, amount in GHS and date the money is sent.
      </p>
    </span>
    <hr />
    <span>
      <FaEnvelope />
      <p>Details of payment of the <b>subscription fee</b> of your chosen famiweb plan using prevailing <br/>
        BOG exchange rate to Momo number +233 245 970043. <br/>
        The details should include the exchange rate, name of <br/>
        sender, amount in GHS and date the money is sent.
      </p>
    </span>
    <hr />
    <p><b>Note: </b>It will take up to 3 weeks for your famiweb website to be ready.</p>
    </div>
   </Card>
   </div>
  </div>
  </div>
    </section>
  )
}

export default Contact