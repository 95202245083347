import React from 'react'
import styles from './GoldItem.module.scss'
import { Link } from 'react-router-dom'



const GoldItem = ({ deed, pay, order, photo, vit, orbit }) => {
    return (
      <>
       <div className={styles["price-box"]}>
          <div className={styles.box2}>
             <p>{deed}</p>
             <h4 className={styles.cc}>{pay}</h4>
          </div>
  
          <div className={styles["price-list"]}>
           <ul>
             <li>{order}</li>
             <li>{photo}</li>
             <li>{vit}</li>
             <li>{orbit}</li>
           </ul>
           <Link to="/contact" className={styles.btn2}>Subscribe</Link>
          </div>
         </div>
      </>
    )
  }

export default GoldItem