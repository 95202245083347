import React from 'react'
import {  useSelector } from 'react-redux'
import AboutItem from '../aboutItem/AboutItem'
import { selectAbouts } from '../../../redux/slice/aboutSlice'
// import styles from './AboutList.module.scss'


const AboutList = () => {
const abouts = useSelector(selectAbouts)


  return (
    <> 
        <div >
        {abouts.map((about) => {
            return (
               <div key={about.id}>
             <AboutItem {...about}  about = {about} />
             </div> 
            )
        }
            
        )}
     
        </div>
    </>
  )
}

export default AboutList